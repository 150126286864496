import theme from "src/gatsby-theme-docz/theme/index.js";

export const wrapperStyles = {
  display: "flex",
  gap: "24px",
  marginBottom: "24px",
};

export const linkStyles = {
  color: theme.colors.primary,
  textDecoration: "none",
  cursor: "pointer",
};

export const iframeWrapperStyles = {
  flex: "1 0 480px",
  maxWidth: "480px",
};
