import * as React from "react";
import { h3 as H3 } from "src/gatsby-theme-docz/components/Headings";
import { wrapperStyles, linkStyles, iframeWrapperStyles } from "./styles.js";

export function Player({ url, title, timestamps }) {
  const id = title.toLowerCase().replace(/\s+/g, "-");
  const iframeId = `${id}-iframe`;

  function updateIframeUrl(timestamp) {
    const [minutes, seconds] = timestamp.split(":")
    const videoSeconds = Number(minutes) * 60 + Number(seconds)

    document.getElementById(iframeId).src = `${url}?start=${videoSeconds}`;
  }

  return (
    <div style={wrapperStyles}>
      <iframe
        id={iframeId}
        style={iframeWrapperStyles}
        src={url}
        width="480"
        height="320"
        allow="autoplay"
        allowFullScreen
      ></iframe>
      <div>
        <H3 id={id}>{title}</H3>
        {timestamps && (
          <ul>
            {timestamps.map(({ timestamp, name }) => {
              return (
                <li key={timestamp}>
                  <a
                    style={linkStyles}
                    onClick={() => updateIframeUrl(timestamp)}
                  >
                    {timestamp}
                  </a>{" "}
                  - {name}
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}
