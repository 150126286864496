// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---docs-adding-an-instance-to-auto-patcher-mdx": () => import("./../../../docs/Adding-an-instance-to-AutoPatcher.mdx" /* webpackChunkName: "component---docs-adding-an-instance-to-auto-patcher-mdx" */),
  "component---docs-api-usage-mdx": () => import("./../../../docs/api-usage.mdx" /* webpackChunkName: "component---docs-api-usage-mdx" */),
  "component---docs-baseline-mdx": () => import("./../../../docs/Baseline.mdx" /* webpackChunkName: "component---docs-baseline-mdx" */),
  "component---docs-bootcamp-videos-mdx": () => import("./../../../docs/bootcamp-videos.mdx" /* webpackChunkName: "component---docs-bootcamp-videos-mdx" */),
  "component---docs-cli-mdx": () => import("./../../../docs/CLI.mdx" /* webpackChunkName: "component---docs-cli-mdx" */),
  "component---docs-firewall-configuration-mdx": () => import("./../../../docs/firewall-configuration.mdx" /* webpackChunkName: "component---docs-firewall-configuration-mdx" */),
  "component---docs-managing-api-keys-mdx": () => import("./../../../docs/managing-api-keys.mdx" /* webpackChunkName: "component---docs-managing-api-keys-mdx" */),
  "component---docs-managing-permissions-mdx": () => import("./../../../docs/managing-permissions.mdx" /* webpackChunkName: "component---docs-managing-permissions-mdx" */),
  "component---docs-notifications-mdx": () => import("./../../../docs/Notifications.mdx" /* webpackChunkName: "component---docs-notifications-mdx" */),
  "component---docs-patching-events-mdx": () => import("./../../../docs/patching-events.mdx" /* webpackChunkName: "component---docs-patching-events-mdx" */),
  "component---docs-patching-logs-placement-mdx": () => import("./../../../docs/patching-logs-placement.mdx" /* webpackChunkName: "component---docs-patching-logs-placement-mdx" */),
  "component---docs-pre-post-patching-actions-hooks-mdx": () => import("./../../../docs/Pre&Post-patching-actions-hooks.mdx" /* webpackChunkName: "component---docs-pre-post-patching-actions-hooks-mdx" */),
  "component---docs-quickstart-mdx": () => import("./../../../docs/Quickstart.mdx" /* webpackChunkName: "component---docs-quickstart-mdx" */),
  "component---docs-reports-mdx": () => import("./../../../docs/reports.mdx" /* webpackChunkName: "component---docs-reports-mdx" */),
  "component---docs-scheduling-patching-plan-mdx": () => import("./../../../docs/Scheduling-patching-plan.mdx" /* webpackChunkName: "component---docs-scheduling-patching-plan-mdx" */),
  "component---docs-setting-up-permissions-mdx": () => import("./../../../docs/setting-up-permissions.mdx" /* webpackChunkName: "component---docs-setting-up-permissions-mdx" */),
  "component---docs-setting-up-ssm-mdx": () => import("./../../../docs/Setting-up-SSM.mdx" /* webpackChunkName: "component---docs-setting-up-ssm-mdx" */),
  "component---docs-start-mdx": () => import("./../../../docs/start.mdx" /* webpackChunkName: "component---docs-start-mdx" */)
}

