/**
 * Copyright 2020 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import dark from "./dark";

export default {
  dark,
  light: dark
};
